$kolor: #FCDE51;
$kolor_text: #1a1a1a;

@import "google-fonts.css";

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  font-family: 'Muli', sans-serif !important;
}
.text-red, .text-red label{
  color: #d00 !important;
  font-weight: bold !important;
}
.table-wrapper{
  max-height: calc(100vh - 300px);
  overflow: auto;
}
table.table{
  text-align: left;
  .highlighted{
    background-color: #ddd;
  }
}
.table-wrapper .table tbody tr{ cursor: pointer; }
#root{
  display: grid;
  min-height: 100%;
  a, a:hover, a:active, a:visited{
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
  tr {
    background-color: #fff;
  }
  tr:nth-child(even) {
    background-color: #ededed;
  }
  thead tr {
    background-color: $kolor;
    color: $kolor_text;
  }
  td {
    padding: 2px 4px;
    border: 1px solid #fff;
  }
  .pull-right{ float: right; }
  .pull-left{ float: left; }
  .center{ text-align: center; }
  .left{ text-align: left; }
  .right{ text-align: right; }
  table.transparent{
    tr{
      background-color: transparent;
      td{
        border: 0 none;
        text-align: left;
      }
    }
  }
  header {
    height: 60px;
    width: 100%;
    position: relative;
    margin: 0 auto 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav{ height: 100%; }
    button.logout{
      background-color: #9fa2b4;
      display: block;
      height: 100%;
      padding: 5px 40px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      border: none;
      cursor: pointer;
      svg{
        fill: #fff;
        margin-right: 10px;
        vertical-align: sub;
      }
      &:hover{
        background-color: $kolor;
        color: $kolor_text;
        svg{ fill: $kolor_text; }
      }
    }
    .auth_info{
      font-size: 13px;
      font-weight: 400;
      line-height: 60px;
      color: #373f51;
      >span{
        padding-right: 60px;
        padding-left: 22px;
      }
      .auth_info_name{
        background: url(../assets/user.png) no-repeat center left;
      }
      .auth_info_pakowalnia{
        background: url(../assets/packing_fac.png) no-repeat center left;
      }
    }
    .nav{
      align-items: flex-end;
    }
    .logo{
      width: 170px;
      height: 57px;
      margin-left: 18px;
      background: url(../assets/logotype.png) no-repeat center left / contain #ffffff;
    }
  }
  article {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    grid-template-areas: "header" "main" "footer";
    header{ grid-area: header; }
    main{ grid-area: main; }
    footer{ grid-area: footer; }
  }
  main{
    font-size: 16px;
    padding: 10px 15px;
    h1{
      font-size: 18px;
      font-weight: 700;
    }
    h2{
      font-size: 24px;
      font-weight: 300;
      line-height: 34px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 20px;
      .subheader{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    h3{
      text-align: center;
      margin-bottom: 25px;
    }
    h4{
      color: #000;
      font-size: 21px;
      font-weight: 800;
    }
    input{
      border-radius: 4px;
      border: solid 1px #d2d2d9;
    }
    table.tab{
      width: 100%;
      border-bottom: 2px solid #888;
      th{
        padding: 2px 8px;
        font-weight: normal;
      }
      td{
        padding: 2px 8px;
        border: none;
      }
    }
    button:not(.react-datepicker__navigation):not(.filepond--file-action-button), .button, input[type="submit"] {
      background-color: $kolor;
      color: $kolor_text;
      cursor: pointer;
      border: 0 none;
      border-radius: 0.25rem;
      line-height: 1;
      padding: 5px 20px;
      min-width: 260px;
      height: 46px;
      font-size: 15px;
      font-weight: 800;
      &.secondary{
        background-color: #fff;
        border: 1px solid #666;
        color: #000;
      }
      &[disabled]{
        background-color: #ddd;
        color: #aaa;
      }
      &.btn-normal{
        font-size: 18px;
      }
      &.btn-sm{
        font-size: 16px;
        padding: 0.6em 1.2em;
      }
    }
    .center_wrapper{
      display: grid;
      height: 100%;
      justify-content: center;
      align-content: center;
    }
    .buttons_wrap{
      margin-bottom: 30px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      &.buttons_wrap--right{
        justify-content: flex-end;
      }
      button + button{
        margin-left: 30px;
      }
    }
    .ramka{
      background-color: #fff;
      padding: 20px 35px;
      margin-bottom: 40px;
      margin-top: 10px;
      border-radius: 6px;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      >h4{
        padding: 6px 15px 15px;
      }
      label, .label{
        margin-bottom: 0;
        color: #878a99;
        font-size: 16px;
        font-weight: 400;
        padding-right: 3px;
      }
      .buttons_wrap{
        margin-bottom: 20px;
      }
    }
    .react-autosuggest__container {
      display: inline-block;
      width: 100%;
      position: relative;
      margin: 50px 0 20px;
      .react-autosuggest__suggestions-container {
        position: absolute;
        width: 100%;
        background-color: #fff;
        top: 100%;
        border: 1px solid #A9A9A9;
        opacity: 0;
        ul.react-autosuggest__suggestions-list {
          list-style: none;
          text-align: left;
          margin: 5px 0;
          padding-left: 0;
          li{
            padding-left: 0.8em;
            cursor: pointer;
            &:hover{
              background-color: #E0E0E0;
            }
          }
        }
      }
      .react-autosuggest__suggestions-container--open{
        opacity: 1;
      }
    }
    .searchBox{
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding: 0.8em;
      padding-left: 3.5em;
      width: 100%;
      background: url('../assets/barcode.png') 20px center no-repeat #fff;
    }
    .breadcrumb{
      padding: 0;
      display: block;
      margin-bottom: 1.5rem;
    }
    .field {
      position: relative;
      margin-top: 20px;
      label {
        font-weight: 700;
        display: block;
        position: absolute;
        top: 14px;
        left: 15px;
        color: #9fa2b4;
        background-color: transparent;
        cursor: text;
        font-size: 14px;
        transition: all .15s ease-in-out 0s;
      }
      input, select{
        height: 48px;
        font-size: 15px;
        line-height: 24px;
        transition: all .15s ease-in-out 0s;
        width: 100%;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        font-weight: 600;
        &:not(:placeholder-shown), &:focus {
          background-color: #FFFFFF;
          border-color: #9fa2b4;
          outline: 0;
          box-shadow: none;
          + label{
            top: -9px;
            font-size: 12px;
            background-color: #fff;
            color: #878a99;
            cursor: pointer;
            padding: 0 5px;
            border-radius: 5px;
            margin-left: -5px;
            font-weight: 400;
          }
        }
        &:focus{
          border-width: 2px;
          border-color: #9fa2b4;
          box-shadow: none;
          + label{
            font-weight: 700;
          }
        }
      }
      input:-internal-autofill-selected{
          background-color: #fff;
      }
    }
    .login_form{
      min-width: 350px;
      border-radius: 6px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      padding: 20px 45px 25px;
      form{
        position: relative;
        h2{
          font-size: 24px;
          color: #666666;
          margin: 0 0 30px;
          padding-top: 15px;
          text-align: center;
          font-weight: 300;
          letter-spacing: -0.5px;
          padding-bottom: 1.5rem;
          margin-bottom: 2rem;
          border-bottom: 1px solid #dee2e6;
        }
      }
      input[type="submit"]{
        display: block;
        width: 100%;
        margin-top: 40px;
      }
      .login-failed {
        color: #e11;
        font-weight: 600;
        text-align: center;
        padding: 1rem 0 0;
        font-size: 14px;
      }
    }

    .filters{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      top: 80px;
      label{
        background-color: #ececec;
        color: #999;
        border: 1px solid #afafaf;
        padding: 2px 8px;
        margin-left: -1px;
        width: 120px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:first-child{
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child{
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &.checked{
          background-color: #fff;
          color: #000;
        }
        input{
          opacity: 0;
          width: 0;
          height: 0;
          position: absolute;
        }
      }
    }

    .elem-list{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 30px;
      .elem-tile{
        color: #151408;
        text-decoration: none;
        font-size: 14px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #fff;
        padding: 15px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-weight: 400;
        display: none;
        &:hover{
          border-color: $kolor;
        }
        &.inactive{
          background-color: #f18b8b;
          color: #ffffff;
        }
        &[data-important="1"]{
          background-color: #ff5a5a !important;
          color: #fff !important;
        }
        h5{
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .infoRow {
          display: flex;
          justify-content: space-between;
        }
        label{
          color: #878a99;
          font-size: 14px;
          font-weight: 400;
          img{
            margin-right: 8px;
            vertical-align: unset;
            opacity: 0.4;
          }
        }
        .elem-footer{
          font-size: 18px;
          padding: 2px 15px;
          background-color: #808080;
          color: #fff;
          overflow: hidden;
          margin: 10px -15px 0;
        }
      }
      &.show_pak{
        .elem-tile[data-multi="0"]{
          display: block;
        }
      }
      &.show_multi{
        .elem-tile[data-multi="1"]{
          display: block;
        }
      }
    }
    .elem-view{
      background-color: #fff;
      padding: 15px 20px;
      margin: 30px 0;
      font-size: 22px;
      label{
        font-size: 22px;
        font-weight: normal;
      }
    }
    .product_suggestions {
      .product_suggestions_columns{
        display: flex;
        justify-content: space-evenly;
        .sug_col{
          width: 100%;
        }
        .sug_col1 .sug_col_tags{
          border-right: 1px solid #ccc;
          height: calc(100% - 40px);
        }
        .sug_col_tags {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          grid-auto-flow: dense;
        }
        h5{
          margin-bottom: 1rem;
        }
      }
      span {
          height: fit-content;
          min-height: 37px;
          font-size: 18px;
          margin: 3px 8px;
          padding: 0.3em 0.8em;
          display: inline-block;
          background-color: #e0e0e0;
          cursor: pointer;
          border-radius: 3px;
          &.long{
            grid-column-end: span 3;
          }
      }
      .sug_col2:first-child span.long {
          grid-column-end: span 2;
      }
    }
    .boxy{
      border-collapse: separate;
      width: calc(100% + 35px);
      border-spacing: 20px;
      margin: 0 -20px;
      tr{ background-color: transparent; }
      td{
        border-radius: 6px;
        box-shadow: 0px 3px 4px rgba(76,67,25,0.1);
        text-align: center;
        vertical-align: middle;
        padding: 0;
        background-color: #fff;
        font-size: 36px;
        font-weight: 700;
        &.errors{
          background-color: #e82d2d;
          border-color: #e82d2d;
        }
        &.done{
          background-color: #04bf62;
          border-color: #04bf62;
          position: relative;
          &:before{
            content: "";
            width: 36px;
            height: 36px;
            display: block;
            border-radius: 50%;
            background: url(../assets/icons/green-check.png) center center no-repeat #ececec;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:not(.done){
          cursor: pointer;
        }
      }
    }
    .box_info .boxy td:not(.done){ cursor: default; }
    .progressbar-gutter{
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 5px;
      text-align: left;
      z-index: 5;
      .progressbar-bar{
        height: 100%;
        background-color: #3d3;
        transition: width 300ms ease-in-out;
      }
    }
    .f_group{
      display: grid;
      grid-template-columns: minmax(0, 45%) minmax(0, 1fr);
      grid-template-areas: "labels data";
      padding: 14px 0;
      border-top: 1px solid #e8e8e8;
      line-height: 17px;
      label{
        grid-area: labels;
        padding-left: 32px;
        position: relative;
        &:before{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-position: left 0;
          opacity: 0.4;
        }
      }
      >div{
        grid-area: data;
      }
      &.notes label:before{ background-image: url(../assets/icons/notes.png); }
      &.carrier label:before{ background-image: url(../assets/icons/carrier.png); }
      &.date_order label:before{ background-image: url(../assets/icons/date.png); }
      &.amount_total label:before{ background-image: url(../assets/icons/amount.png); }
      &.delivery label:before{ background-image: url(../assets/icons/location.png); }
      &.invoice label:before{ background-image: url(../assets/icons/invoice.png); }
      &.allegro_account label:before,
      &.allegro_client_login label:before{ background-image: url(../assets/icons/allegro.png); }
      &.warehouseman label:before{ background-image: url(../assets/icons/person.png); }
      &.integration_login label:before{ background-image: url(../assets/icons/person.png); }
      &.stock_location label:before{ background-image: url(../assets/icons/carrier.png); }
      &.qty label:before{ background-image: url(../assets/icons/qty.png); }
      &.price label:before{ background-image: url(../assets/icons/price.png); }
      &.allegro_account label:before{ opacity: 1; }
    }
    .pakowanie{
      .pakowanie_header{
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        button, input[type="submit"]{
          padding: 0.8em 1em;
        }
        .scanner_wrapper {
          width: 100%;
          padding: 0 5%;
          position: relative;
          input{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: solid 1px #d2d2d9;
            padding-left: 3.5em;
            background: url('../assets/barcode.png') 20px center no-repeat #fff;
          }
        }
        .wydruk_kw_wrapper {
          display: inline-block;
          position: relative;
          width: 100%;
          text-align: right;
          padding: 11px 20px;
          input[type="checkbox"]{
            background: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -webkit-border-radius:0;
            border-radius:0;
            width: 35px;
            height: 35px;
            padding: 0;
            border: 2px solid #666;
            vertical-align: middle;
            outline: none;
            margin-right: 8px;
            margin-bottom: 2px;
            &:checked + label{
              &:before{
                content: '';
                display: block;
                position: absolute;
                background: #666;
                width: 5px;
                height: 12px;
                transform: rotate(135deg);
                left: -32px;
                top: 11px;
              }
              &:after{
                content: '';
                display: block;
                position: absolute;
                background: #666;
                width: 5px;
                height: 20px;
                transform: rotate(45deg);
                left: -24px;
                top: 4px;
              }
            }
          }
          label{
              font-size: 19px;
              cursor: pointer;
              min-width: 0;
              position: relative;
          }
      }
      }
      .pakowanie_order{
        padding-left: 30px;
        padding-right: 30px;
        h4{
          padding-left: 40px;
          position: relative;
          &:before{
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: url(../assets/icons/order.png) no-repeat left 7px transparent;
              opacity: 0.4;
              pointer-events: none;
          }
        }
        .order_data{
          overflow-y: auto;
          .notes > div{
            color: #ec2d2d;
            font-weight: bold;
            white-space: normal;
          }
        }
        .shippinglogo > img{
          margin-top: 5px;
          max-height: 36px;
        }
        .add_package_wrapper{
          text-align: center;
          margin-bottom: 10px;
          margin-top: 10px;
          button{
            padding: 0.8em 1em;
            font-size: 20px;
            + button{
              margin-top: 5px;
            }
          }
        }
        .carriers_radios {
          display: grid;
          grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
          .carrier_radio {
            float: left;
            width: 140px;
            margin-right: 5px;
            text-align: center;
            position: relative;
            input[type="radio"]{
              position: absolute;
              display: none;
            }
            label{
              font-weight: normal;
              font-size: 14px;
              display: inline-block;
              width: 100%;
              max-width: 100%;
              min-width: 0;
              line-height: 16px;
              padding: 60px 3px 7px;
              cursor: pointer;
              position: relative;
              z-index: 0;
            }
            input[type="radio"]:checked + label:after{
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border: 2px solid #5d5d5d;
              border-radius: 3px;
              background-color: rgba(154, 255, 153, 0.5);
              z-index: -1;
            }
            .carrier_thumbnail{
              height: 60px;
              width: 100%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              position: absolute;
              pointer-events: none;
            }
          }
        }
        .notes{
          .checkbox.mainCheckbox.uwagiCheckbox {
            float: left;
            margin: -4px 5px 5px -50px;
            cursor: pointer;
            position: relative;
            .checkbox-inner{
              margin-left: -10px;
            }
            + label,
            + label + div{
              padding-left: 100px;
              display: block;
            }
          }
          .note_text{ cursor: pointer; }
        }
      }
      .checkbox{
        position: relative;
        &.clickable{ cursor: pointer; }
        width: 42px;
        height: 42px;
        border-radius: 6px;
        border: 2px solid #cdcdcd;
        display: flex;
        align-items: center;
        justify-content: center;
        .check {
          opacity: 0;
        }
      }
      .checkbox.checked{
        background-color: #04bf62;
        border-color: #04bf62;
        .check {
          opacity: 1;
        }
      }
      .pakowanie_products{
        overflow-y: auto;
        .ramka.produkt{
          padding: 0;
          overflow: hidden;
          margin-bottom: 25px;
          position: relative;
          > div {
            display: flex;
            position: relative;
            justify-content: space-between;
            &.bundle_title {
              margin: 0 0 25px;
            }
          }
          .lightbox-wrapper{
            width: 240px;
            height: 180px;
            position: relative;
            button.img-open{
              padding: 15px 5px;
              border: 0;
              cursor: zoom-in;
              width: 100%;
              height: 100%;
              min-width: 0;
              background-color: transparent;
              >img{
                width: 100%;
                height: auto;
                max-height: 100%;
                object-fit: contain;
              }
            }
            .lightbox{
              background: rgba(0, 0, 0, 0.6);
              height: 100%;
              border-radius: 0;
              img{
                object-fit: contain;
                background-color: #fff;
              }
            }
            .lightbox-visible{
              transition: opacity 200ms linear;
            }
            .img-close {
              cursor: zoom-out;
            }
          }
          .productVariantValue, .defectValue {
            display: inline-block;
            border: 2px solid #d3d3d3;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 5px;
            background-color: #fff;
            padding: 2px 8px;
            margin-right: 10px;
            &.selected {
              background-color: $kolor;
              color:  $kolor_text;
              border-color: $kolor;
            }
          }
          .default_code{
            color: #959ca2;
            font-size: 16px;
            font-weight: 400;
          }
          .product_details {
            flex-grow: 1;
            padding: 20px 25px;
            border-left: 1px solid #e6e6e6;
            .f_group{
              grid-template-columns: minmax(max-content, 50%) minmax(max-content, 1fr);
              border-top: 0 none;
              padding: 4px 0;
              > div{
                max-width: 15vw;
              }
            }
          }
          .product_checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            background-color: #fafafa;
            border-left: 1px solid #e6e6e6;
            transition: all 300ms ease-in-out;
            &.clickable{ cursor: pointer; }
          }
          &.last_scanned{
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0px;
              border-style: solid;
              border-width: 70px 0 0 70px;
              border-color: transparent transparent transparent #e5e6ea;
              z-index:1;
            }
            &:after{
              content: "";
              width: 100px;
              height: 100px;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: #fafafa;
              z-index:2;
              transform: rotate(45deg) translate(0px, 72px);
            }
            >div:before{
              content: "";
              width: 43px;
              height: 43px;
              position: absolute;
              bottom: 0;
              left: 0;
              background: url(../assets/icons/scan.png) center center no-repeat transparent;
              z-index:3;
              opacity: 0.4;
            }
          }
          &.grey{
            .product_details, .lightbox-wrapper .img-open{
              opacity: 0.4;
            }
            .checkbox.checked{
              background: #666;
            }
            h4{
              background-color: #d3d3d3;
            }
            .productVariantValue, .checkbox{
              pointer-events: none;
            }
          }
          &.red {
            background-color: #ea4747;
            label, h4, span{
              color: #fff;
            }
            h4{ background-color: #545454; }
            .product_checkbox, .product_checkbox .checkbox.checked{
              background-color: #ea4747;
              .checkbox-inner:before, .checkbox-inner:after{
                background-color: #fff;
              }
            }
          }
          .bundleWrapper{
            margin: 0;
            .ramka.bundleItemList {
              position: relative;
              clear: both;
              padding: 0px;
              width: 100%;
              background-color: #fafafa;
              border-top: 1px solid #e6e6e6;
              margin: 0 0 3px;
              table{ width: 100%; }
              tr{
                background-color: transparent;
                &:last-child td { border-bottom: 0 none; }
              }
              span.smaller{
                font-size: 0.7rem;
                color: #888;
              }
            }
            .bundleItemList th,
            .bundleItemList td {
              text-align: left;
              padding: 12px 5px 7px;
              border: none;
              border-bottom: 1px dashed #000;
            }
            .bundleItemList td:nth-child(2) {
              text-align: right;
              white-space: nowrap;
              padding-right: 5px;
            }
            .bundleItemList td:last-child {
              padding: 0;
              padding-top: 3px;
              border-bottom: none;
              width: 1%;
              padding-right: 28px;
              padding-left: 20px;
              .checkbox{
                position: relative;
                &.clickable{ cursor: pointer; }
                width: 37px;
                height: 37px;
                border-radius: 6px;
                border: 2px solid #cdcdcd;
                .check {
                  opacity: 0;
                  transform: scale(0.8);
                }
              }
              .checkbox.checked{
                border-color: #04bf62;
                .check {
                  opacity: 1;
                }
              }
            }
            .bundleItemList {
              .checkbox{
                position: relative;
                &.clickable{ cursor: pointer; }
                width: 42px;
                height: 42px;
                border-radius: 6px;
                border: 2px solid #cdcdcd;
                .check {
                  opacity: 0;
                }
              }
              .checkbox.checked .check {
                opacity: 1;
              }
            }
            .bundleItemList th {
              background-color: $kolor;
              color:  $kolor_text;
              font-weight: 300;
            }
          }
          .dropshipping_label{
            padding: 5px 8px;
            background-color: #ddd;
            position: absolute;
            z-index: 9;
            left: 0;
            top: 0;
            width: 240px;
            text-align: center;
            pointer-events: none;
            color: #555;
            font-weight: 600;
            color: $kolor_text;
            background-color: $kolor;
            display: none;
          }
          &.dropshipping_product .dropshipping_label{
            display: block;
          }
        }
      }
    }
    div.done {
      text-align: center;
      padding: 15px 35px;
      margin-bottom: 0;
      .summary {
        text-align: left;
        max-width: 1100px;
        display: inline-block;
        padding: 0 20px;
        margin-top:10px;
        width: 500px;
        h4{
          text-align: center;
          margin-bottom: 1.5rem;
          margin-top: 0.5rem;
        }
        label {
          width: 200px;
        }
        .shippinglogo > img{
          margin-top: 5px;
          max-height: 36px;
        }
        .printInvoice{
          text-align: center;
        }
      }
      &.special{
        border: 10px solid #F00;
        h3 {
          background-color: #f00;
        }
        button {
          background-color: #f00;
        }
        h4 {
          background-color: #f00;
        }
      }
      button.print_invoice:before, button.print_label:before{
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        opacity: 0.4;
      }
      button.print_invoice:before{
        background: url(../assets/icons/print.png) center center no-repeat transparent;
      }
      button.print_label:before{
        background: url(../assets/icons/badge.png) center center no-repeat transparent;
      }
    }
  }
  footer{
    font-weight: 400;
    font-size: 0.8rem;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $kolor_text;
    background-color: $kolor;
    padding: 0 30px;
    p{
      margin: 0;
    }
    select.lang{
      background-color: transparent;
      border: none;
      font-weight: 300;
      padding-right: 15px;
      margin-right: -15px;
      background: transparent;
      -webkit-appearance: none;
      option{ color: #000; }
    }
    .selectArr, .worldIcon{
      fill: $kolor_text;
      pointer-events: none;
    }
    .worldIcon{
      margin-right: 8px;
      vertical-align: sub;
      opacity: 0.7;
    }
    .opacity-3{ opacity: 0.4; }
  }
  article[data-view="Order"]{
    header{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;
      display: none;
    }
    main{
      padding-top: 0px;
    }
    .pakowanie_header{
      position: fixed;
      width: 100%;
      left: 0;
      padding: 15px;
      z-index: 4;
      background-color: #ececec;
      background: linear-gradient(180deg, rgba(236,236,236,1) 80%, rgba(255,255,255,0) 100%);
    }
    .pakowanie_content{
      padding-top: 65px;
    }
  }

  .shipping_header, .materials_header{
    position: fixed;
    z-index: 4;
    top: 52px;
    padding: 10px 0 25px;
    width: calc(100% - 57px);
    background-color: #fff;
    background: linear-gradient(180deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
  }
  .materials_header{
    width: calc(100% - 40px);
  }
  .shipping{
    margin-top: 40px;
    text-align: left;
    .f_group {
      grid-template-columns: minmax(0, 220px) minmax(0, 1fr);
      label{ padding-left: 0; }
    }
    input[type="text"]{
      height: 27px;
      margin: -3px 0;
      width: 100%;
    }
    .row{ margin-bottom: 30px; }
    .table.parcels{
      margin-bottom: 0;
      input{
        width: 70px;
      }
    }
    button.addRow{
      background-color: transparent;
      color: #000;
      padding: 0;
      height: auto;
      min-width: 0;
      margin: 0 6px 10px;
      &:hover{ text-decoration: underline; }
    }
    button.removeParcel{
      min-width: 0;
      width: 29px;
      height: 29px;
      padding: 10px;
      background: url(../assets/trash.png) no-repeat center center #fff;
      border: 1px solid #000;
    }
  }

  .materials_list{
    margin-top: 60px;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    &__tile{
      padding: 10px;
      border: 1px solid #ff7878;
      background-color: #ff7878;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      label{
        font-weight: bold;
        font-size: 18px;
        height: 100%;
        min-height: 50px;
        display: table;
        span{
          display: table-cell;
          vertical-align: middle;
        }
      }
      .counter{
        display: flex;
        button{
          height: 45px !important;
          min-width: 45px !important;
          padding: 0 !important;
          &:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        input{
          height: 45px;
          width: 100%;
          text-align: center;
          font-size: larger;
          border-radius: 0;
        }
      }
      &[data-val="1"]{
        background-color: #a7ffa7;
        border-color: #a7ffa7;
      }
      &[data-val="0"]{
        background-color: #fff;
        border-color: #ededed;
      }
    }
  }

  button.highlighted {
    background-color: #f00 !important;
    color: #fff !important;
    border: 2px solid #000 !important;
  }

  /* ================ Spinner =================*/
  @import "spinner.css";
  /* ================ Spinner =================*/
}
/* ================ Popup =================*/
@import "popup.scss";
/* ================ Popup =================*/
/* ============== Responsive ==============*/
@media (max-width: 1200px) {
  #root main .f_group{
    display: block;
  }
}
/* ============== Responsive ==============*/
/* ============== OTHER ==============*/
.shipping_list{
  border: 1px solid #dfdfdf;
  margin: 0;
  thead th{
    border: 0 none;
  }
  tbody tr{
    &:nth-child(even){
      background-color: #f8f8f8;
    }
    &:hover{
      background-color: #dfdfdf;
    }
    td{
      border-top: 0 none;
    }
  }
}
